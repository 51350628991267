<template>
  <div class="content-image">
    <img
      src="@/assets/images/bg-content.png"
      alt=""
    >
  </div>
</template>

<script>
export default {
  name: "SignupBackgroundLayout",
  data() {
    return {
      //VARIABLES
    };
  },
};
</script>

<style scoped>
.content-image {
  width: 100%;
  height: 100%;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
</style>